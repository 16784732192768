import React from "react"
import styled from "styled-components"
import Container from "../../layout/container/Container"
import MainParagraph from "../../components/typography/MainParagraph"

const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  @media (max-width: 989px) {
    grid-template-columns: 1fr 5fr 1fr;
  }
  @media (max-width: 389px) {
    display: flex;
    padding: 20px;
  }
`
const StyledMainWrapper = styled.div`
  grid-column: 2/3;
  display: flex;
  flex-direction: column;
  padding: 120px 0;
  @media (max-width: 619px) {
    padding: 60px 0;
  }
  @media (max-width: 429px) {
    padding: 40px 0;
  }
  @media (max-width: 359px) {
    padding: 20px 0;
  }
`
const StyledHeading = styled.h2`
  padding-bottom: 1rem;
  font-size: 1.5rem;
  line-height: 1.6;
`
const StyledPointWrapper = styled.div`
  padding-bottom: 40px;
  &:last-of-type {
    padding-bottom: 0;
  }
`

export default function PrivacyMain() {
  return (
    <Container>
      <StyledGrid>
        <StyledMainWrapper>
          <StyledPointWrapper>
            <StyledHeading>Données privées</StyledHeading>
            <MainParagraph>
            Nous ne collectons aucune données personnelles via ce Site web.
            </MainParagraph>
          </StyledPointWrapper>
          <StyledPointWrapper>
            <StyledHeading>Prestataires de services externes</StyledHeading>
            <MainParagraph>
            Notre Site web repose sur l'infrastructure de prestataires externes, susceptibles de garder une trace de votre passage. Ceci est valable pour toute vos excursions sur le web.
            </MainParagraph>
          </StyledPointWrapper>
          <StyledPointWrapper>
            <StyledHeading>Statistiques</StyledHeading>
            <MainParagraph>
            Pour les statistiques d'accès à notre site, nous utilisons Google Analytics qui nous restitue des données de fréquentation totalement anonymes.
            </MainParagraph>
          </StyledPointWrapper>
        </StyledMainWrapper>
      </StyledGrid>
    </Container>
  )
}
